@use 'components/shared/core.scss' as *;

.cash-breakdown-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    padding-left:$size-xxs;
    padding-right: $size-xxs;
    .cash-breakdown.grid {
        grid-row-gap: $size-5xs;
        grid-column-gap: 0px;
        .red-text {color: $red}    
        .right {text-align: right}
        .bold {font-weight: bold}
        .divider {
            box-sizing: border-box;
            border-bottom : 2px solid $primary-color-darker;
        }
        div {
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }  
}

.cash-breakdown-title {
    grid-column: span 4;
    text-align: center;
    font-weight: bold;
    font-size: $size-xxs;
    margin-top: $size-4xs;
}

