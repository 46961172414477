@use 'components/shared/core.scss' as *;
@use 'sass:color';

.cash-display-container {
    grid-column: auto / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $size-xxs;
    font-weight: bold;
    text-align: center;
    box-sizing: border-box;
    &.stacker {
        .cash-display {
            background-color: $purple;
        }
    }
}

.cash-display-label {
    height: $size-large;
    line-height: $size-large;
}

.cash-display {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 250px;
    width: 100%;
    border-radius: $radius;
    background-color: $teal;
    margin-bottom: $size-4xs;
    box-sizing: border-box;
}

.cash-display-column {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: $size-xxs;
}
.cash-display-denom {
    height: $size-mid;
    line-height: $size-mid;
}

.cash-display-bar-container {
    position: relative;
    width: 45%;
    height: calc(100% - $size-mid * 2);
}

.cash-display-bar-display {
    position: absolute;
    width: 100%;
    bottom : 0;
    z-index: 1;
}

.cash-display-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    background-image: linear-gradient(135deg, color.scale($green, $lightness: 20%) 0%, $green 60%);
    transition: all .2s;
    z-index: 0;
}

.cash-display-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.cash-display-values {
    height: $size-mid;
    line-height: $size-mid;
    width: 70%;
    border-top: 2px solid $white;
    box-sizing: border-box;
}
